import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import GameSpec from "../sdk/gameSpec";
import { HouseContext } from "./HouseContext";
import { ProgramContext } from "./ProgramContext";
import House from "../sdk/house";
import { GameType } from "../sdk/enums";
import {
  Keypair,
  PublicKey,
  Transaction,
} from "@solana/web3.js";
import { IPlatformGame } from "../types/game";
import { PlayerContext } from "./PlayerContext";
import { NetworkContext } from "./NetworkContext";
import { BalanceContext } from "./BalanceContext";
import { GAME_SPEC_TOKEN_STATUS_TAKING_BETS, GAME_STATUS_TAKING_BETS, LAMPORT_TOPUP_AUTO_SIGNER, MIN_LAMPORTS_AUTO_SIGNER } from "../sdk/constants";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";
import { useNavigate } from "react-router";
import { WrappedWalletContext } from "./WrappedWalletContext";
import CoinFlip from "../sdk/games/CoinFlip";
import { PlayerTokenContext } from "./PlayerTokenContext";
import { bs58 } from "@coral-xyz/anchor/dist/cjs/utils/bytes";
import BlackJack, { BlackjackInstance } from "../sdk/games/BlackJack";
import PlayerToken from "../sdk/playerToken";
import Slide from "../sdk/games/Slide";
import Plinko from "../sdk/games/Plinko";
import Limbo from "../sdk/games/Limbo";
import Dice from "../sdk/games/Dice";
import GameInstanceSolo from "../sdk/gameInstance";
import { SessionAuthorityContext } from "./SessionAuthorityContext";
import SlotsThree from "../sdk/games/SlotsThree";
import { useLocation } from "react-router-dom";
import Baccarat from "../sdk/games/Baccarat";
import Jackpot from "../sdk/games/Jackpot";
import Tower, { TowerInstance } from "../sdk/games/Tower";
import Roulette from "../sdk/games/Roulette";
import Keno from "../sdk/games/Keno";
import CrashMulti from "../sdk/games/CrashMulti";
import Wheel from "../sdk/games/Wheel";
import Mines from "../sdk/games/Mines";
import * as base58 from 'bs58';
import { sha256 } from 'js-sha256';
import { toGameSpecTokenStatus } from "../sdk/utils";
import { APP_NETWORK_TYPE } from "../types/chain";
import { sendTransaction } from "../sdk/transactions";

export interface IGameSpecValidation {
  takingBets: boolean;
  tokenTakingBets: boolean;
}
export type GameInstance = BlackjackInstance | GameInstanceSolo;

interface ResultModalData {
  title?: string;
  multiplier?: number;
  payout?: string;
  isModalVisible?: boolean;
  hideTimeout?: number;
  tokenIcon?: string;
  modalBgColor?: string;
  shouldHideBackground?: boolean;
  className?: string;
  onModalClose?: () => void;
}
export interface IGameContext {
  gameSpec: GameSpec | undefined;
  gameInstance: BlackjackInstance | null;
  setGameInstance: React.Dispatch<React.SetStateAction<GameInstance | null>>
  loadGameInstance: () => void | Promise<GameInstance | null>;
  updateGameInstance: (
    gameInstance: GameInstance | null,
    chain: APP_NETWORK_TYPE
  ) => void;
  gameSpecLoaded: boolean;
  gameConfig: IPlatformGame | undefined;
  initAndBet: (
    inputs: object,
    wager: number,
    clientSeed: Buffer,
    onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
    onErrorCallback?: Function,
    onUserRejected?: Function,
    secondsBeforeExpiry?: number,
    identifierPubkey?: PublicKey
  ) => Promise<string | undefined>;
  setResultModalData: (obj: ResultModalData) => void;
  resetResultModalData: Function;
  resultModalData: ResultModalData;
  gameInstancePubkey: PublicKey | null;
  setGameInstancePubKey: React.Dispatch<React.SetStateAction<PublicKey | null>>
  gameUptoDateWithChain: boolean

}

export const GameContext = createContext<IGameContext>({} as IGameContext);

export const WIN_MODAL_ANIMATION_DURATION = 200;

export const loadAssociatedGameSpec = async (
  gameType: GameType,
  hse: House,
  gsPubkey: PublicKey
): Promise<GameSpec> => {
  switch (gameType) {
    case GameType.CoinFlip:
      const coinFlip = await CoinFlip.load(hse, gsPubkey);
      return coinFlip;
    case GameType.BlackJack:
      const blackJack = await BlackJack.load(hse, gsPubkey);
      return blackJack;
    case GameType.Slide:
      const slide = await Slide.load(hse, gsPubkey);
      return slide;
    case GameType.Jackpot:
      const jackpot = await Jackpot.load(hse, gsPubkey);
      return jackpot;
    case GameType.Tower:
      const tower = await Tower.load(hse, gsPubkey);
      return tower;
    case GameType.Plinko:
      const plinko = await Plinko.load(hse, gsPubkey);
      return plinko;
    case GameType.Limbo:
      const limbo = await Limbo.load(hse, gsPubkey);
      return limbo;
    case GameType.Dice:
      const dice = await Dice.load(hse, gsPubkey);
      return dice;
    case GameType.SlotsThree:
      const slots = await SlotsThree.load(hse, gsPubkey);
      return slots;
    case GameType.Baccarat:
      const baccarat = await Baccarat.load(hse, gsPubkey);
      return baccarat;
    case GameType.Roulette:
      return await Roulette.load(hse, gsPubkey);
    case GameType.Keno:
      return await Keno.load(hse, gsPubkey);
    case GameType.Crash:
      return await CrashMulti.load(hse, gsPubkey);
    case GameType.Wheel:
      return await Wheel.load(hse, gsPubkey);
    case GameType.Mines:
      return await Mines.load(hse, gsPubkey);
    default:
      throw new Error(`UNKNOWN GAME SPEC: ${gameType}`);
  }
};

const deriveInstanceSoloDiscriminator = () => {
  return Buffer.from(sha256.digest("account:InstanceSolo")).subarray(0, 8);
}

export const loadInstanceSolosForPlayerAndGame = async (gameSpec: GameSpec, playerToken: PlayerToken, gameType: GameType, chain: APP_NETWORK_TYPE) => {
    const useErProgram = playerToken.houseToken.isDelegated
    const program = useErProgram ? gameSpec.erProgram: gameSpec.baseProgram

    const instanceSolosBuffers = await program.provider.connection.getProgramAccounts(
      program.programId,
        {
          filters: [
            {
              memcmp: {
                offset: 0, // Anchor account discriminator for Player type
                bytes: base58.encode(deriveInstanceSoloDiscriminator()),
              },
            },
            {
              memcmp: {
                offset: 8, // 8 (discriminator)
                bytes: base58.encode(gameSpec.publicKey.toBuffer()),
              },
            },
            {
              memcmp: {
                  offset: 40 + 32, // 8 (discriminator) + 32 (main)
                  bytes: base58.encode(playerToken.publicKey.toBuffer()),
              },
            },
          ],
        },
    );

    const instances = instanceSolosBuffers.map((pnb)=>{
      switch(gameType) {
        case GameType.Tower:
          const tower = TowerInstance.loadInstanceFromBuffer(
            gameSpec,
            pnb.pubkey,
            playerToken,
            chain,
            useErProgram ? undefined: pnb.account.data,
            useErProgram ? pnb.account.data: undefined
          );

          return tower
        case GameType.BlackJack:
          const blackjackInstance: BlackjackInstance | null = BlackJack.loadInstanceFromBuffer(
            gameSpec,
            pnb.pubkey,
            playerToken,
            chain,
            useErProgram ? undefined: pnb.account.data,
            useErProgram ? pnb.account.data: undefined
          );

          return blackjackInstance
        default:
          const instanceSolo: GameInstanceSolo = GameInstanceSolo.loadFromBuffer(
            pnb.pubkey,
            gameSpec,
            playerToken,
            chain,
            useErProgram ? undefined: pnb.account.data,
            useErProgram ? pnb.account.data: undefined
          )

          return instanceSolo
      }
    });

    return instances;
}

export const loadAssociatedGameInstance = async (
  game: GameSpec,
  gameType: GameType,
  chain: APP_NETWORK_TYPE,
  playerToken?: PlayerToken,
  instancePubkey?: PublicKey
) => {
  if (!instancePubkey) {
    return;
  }


  if (playerToken == null) {
    return
  }

  const instanceSolos = await loadInstanceSolosForPlayerAndGame(game, playerToken, gameType, chain)
  
  return instanceSolos != null && instanceSolos.length > 0 ? instanceSolos[0]: null
};

export const initAssociatedGameInstance = (
  game: GameSpec,
  gameType: GameType,
  chain: APP_NETWORK_TYPE,
  playerToken?: PlayerToken,
  instancePubkey?: PublicKey,
  baseState?: any,
  erState?: any
) => {
  switch (gameType) {
    case GameType.BlackJack:
      const blackJackInstance = new BlackjackInstance(
        game as BlackJack,
        instancePubkey,
        playerToken,
        chain,
        baseState,
        erState
      );
      return blackJackInstance;
    case GameType.Tower:
      const towerInstance = new TowerInstance(
        game as Tower,
        instancePubkey,
        playerToken,
        chain,
        baseState,
        erState
      );
      return towerInstance;
    default:
      console.log(
        `INSTANCE CLASS FOR FOLLOWING TYPE DOESN'T EXIST: ${gameType}`
      );
      return null;
  }
};

interface Props {
  gameSpecPubkeyString: string | undefined;
  children: any;
}

export const GameProvider = ({ gameSpecPubkeyString, children }: Props) => {
  // STATE
  const [gameSpec, setGameSpec] = useState<GameSpec>();
  const [gameInstance, setGameInstance] = useState<GameInstance | null>(null);
  const [gameInstancePubkey, setGameInstancePubKey] = useState<PublicKey | null>(null);
  const [gameSpecLoaded, setGameSpecLoaded] = useState(false);

  // CONTEXT
  const { house, houseToken, houseUptoDateWithChain } = useContext(HouseContext);
  const { meta, isUptoDateWithChain } = useContext(ProgramContext);
  const { walletPubkey, solanaRpc, walletUptoDateWithChain } = useContext(WrappedWalletContext);
  const { playerMeta, counter } = useContext(PlayerContext);
  const { chain, client, recentBlockhash, networkCounter, erClient, platformGames, uptoDateWithChain } =
    useContext(NetworkContext);
  const { signerKp, allowsAutoSigning, allowsAutoDeposit, lamportBalance } = useContext(
    SessionAuthorityContext
  );
  const { playerToken, loadPlayerToken, loadPlayerTokens, playerUptoDateWithChain } = useContext(PlayerTokenContext);

  const chainInSync = useMemo(() => {
    return playerUptoDateWithChain == true && houseUptoDateWithChain == true && walletUptoDateWithChain == true && isUptoDateWithChain == true && uptoDateWithChain == true
  }, [playerUptoDateWithChain, houseUptoDateWithChain, walletUptoDateWithChain, isUptoDateWithChain, uptoDateWithChain])

  const gameSpecPubkey = useMemo(() => {
    if (gameSpecPubkeyString == null) {
      return;
    }

    return new PublicKey(gameSpecPubkeyString);
  }, [gameSpecPubkeyString]);

  const gameConfig = useMemo(() => {
    if (gameSpecPubkeyString == null || platformGames == null || uptoDateWithChain == false) {
      return;
    }

    return platformGames.find((game) => {
      return game.gameSpecPubkey == gameSpecPubkeyString;
    });
  }, [gameSpecPubkeyString, platformGames, uptoDateWithChain]);

  const navigate = useNavigate();

  const loadGameInstance = async () => {
    if (!gameSpec || !playerToken || !playerToken?.state) {
      setGameInstance(null);
      return;
    }

    // 
    const instancePubkey = PlayerToken.deriveInstancePubkey(
      playerToken?.publicKey,
      0,
      playerToken?.baseProgram.programId
    );
    const gi = await loadAssociatedGameInstance(
      gameSpec,
      gameConfig?.type as GameType,
      chain,
      playerToken,
      instancePubkey
    );
    setGameInstance(gi);

    return gi
  };

  const updateGameInstance = (
    instance: GameInstance,
    chain: APP_NETWORK_TYPE
  ) => {
    console.warn(`UPDATE GAME INSTANCE`, { instance, dele: chain })
    if (
      !gameSpec ||
      !instance ||
      !playerToken?.state
    ) {
      setGameInstance(null);
      return;
    }

    const updatedInstance = instance.gameSpec.isSolo ? new GameInstanceSolo(instance.instancePubkey, instance.gameSpec, instance.playerToken, chain, instance.baseState, instance.erState) : instance
    setGameInstance(updatedInstance)
  };

  const loadedGameSpecPubkey = useRef<string>()
  const loadedChainRef = useRef<APP_NETWORK_TYPE>()

  const [loadedChain, setLoadedChain] = useState<APP_NETWORK_TYPE>()
  const [gameUptoDateWithChain, setGameUptoDateWithChain] = useState(false)

  useEffect(() => {
    async function loadGameInstanceState(gs: GameSpec, gameType: GameType, gsPubkey: PublicKey, pt: PlayerToken, chain: APP_NETWORK_TYPE) {

      const instancePubkey = PlayerToken.deriveInstancePubkey(
        pt.publicKey,
        0,
        pt.baseProgram.programId
      );
      // load game instance
      const gi = await loadAssociatedGameInstance(
        gs,
        gameType,
        chain,
        pt,
        instancePubkey
      );

      setGameInstance(gi);
    }
    async function loadGameSpec(
      hse: House,
      gsPubkey: PublicKey,
      gameType: GameType,
      chain: APP_NETWORK_TYPE
    ) {
      try {
        console.log("loadGameSpec", {
          gameType,
          gsPubkey: gsPubkey.toString(),
          house: hse,
          houseToken: houseToken,
          playerToken: playerToken,
        });
        const gs = await loadAssociatedGameSpec(gameType, hse, gsPubkey);
        console.log({ gs });

        setGameSpec(gs);

        if (playerToken != null) {
          await loadGameInstanceState(gs, gameType, gsPubkey, playerToken, chain)
        } else {
          setGameInstance(null)
        }

        setGameSpecLoaded(true);
        loadedGameSpecPubkey.current = gsPubkey.toString()
        setLoadedChain(chain)
      } catch (e) {
        console.warn(`Error loading the game spec.`, e);
        navigate(`/`);
      }
    }


    if (
      house == null || houseUptoDateWithChain == false ||
      meta == null || isUptoDateWithChain == false ||
      gameSpecPubkey == null ||
      gameConfig == null || uptoDateWithChain == false
    ) {
      return;
    }

    // CHECK IF WE ALREADY HAVE THE GAME SPEC
    if (gameSpecPubkey.toString() == loadedGameSpecPubkey.current && loadedChain == chain) {
      if ((playerToken != null && playerUptoDateWithChain == true) && gameSpec != null && gameInstance == null) {
        loadGameInstanceState(gameSpec, gameConfig.type as GameType, gameSpecPubkey, playerToken, chain)
      }

      return
    }

    setGameSpec(undefined)
    setGameInstance(undefined)
    loadedGameSpecPubkey.current = undefined
    setLoadedChain(undefined)

    console.log(`<---- LOADING GAME SPEC AND INSTANCES ----->`)
    // TODO - GET PUBKEY AND TYPE FROM GAME_ID
    loadGameSpec(house, gameSpecPubkey, gameConfig.type as GameType, chain);
  }, [house, houseToken, meta, gameSpecPubkey, gameConfig, playerToken]);

  // EITHER INIT AND BET SOLO OR INIT AND BET MULTI
  const initAndBet = useCallback(
    async (
      inputs: object,
      wager: number,
      clientSeed: Buffer,
      onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
      onErrorCallback?: Function,
      onUserRejected?: Function,
      secondsBeforeExpiry?: number
    ) => {
      if (walletPubkey == null || solanaRpc == null || gameSpec == null) {
        console.warn(
          "Issue with wallet pubkey or solana rpc.",
          walletPubkey,
          solanaRpc,
          gameSpec
        );
        return;
      }

      try {
        // NOT USING SESSION KP ON MB YET

        const sessionAuthorityKp =
          allowsAutoSigning == true
            ? Keypair.fromSecretKey(bs58.decode(signerKp))
            : undefined;

        const tx = new Transaction();

        // CHECK IF AUTO DEPOSIT ENABLED, AND WE NEED TO DEPOSIT TO THE PLAYER TOKEN ACCOUNT
        const availableBalance = playerToken?.availableBalance || 0;
        let needsWalletSigner = false;
        let creatingPlayerToken = false;

        // DOESNT NEED A DEPOSIT IF ACTION...
        const actionOnInteractive = gameSpec.isInteractive && inputs.action != null
        const isDelegated = playerToken?.houseToken.isDelegated == true

        if (allowsAutoDeposit && actionOnInteractive == false && isDelegated == false) {
          if (playerToken?.baseState == null) {
            // NEED TO INIT
            const initIxn = await playerToken?.initializeIxn()
            tx.add(initIxn)

            needsWalletSigner = true
            creatingPlayerToken = true
          }

          if (wager > availableBalance) {
            const neededToDeposit = wager - availableBalance;
            const depositIx = await playerToken.depositIxn(neededToDeposit);
            tx.add(depositIx);
            needsWalletSigner = true;
          }

          //CHECK IF WE NEED TO UPDATE THE SESSION AUTHORITY
          if (sessionAuthorityKp != undefined) {
            const MIN_LAMPORTS = houseToken?.isDelegated ? undefined: MIN_LAMPORTS_AUTO_SIGNER

            const needsUpdate = await playerToken?.needsSessionAuthorityUpdate(sessionAuthorityKp.publicKey, MIN_LAMPORTS, lamportBalance)
            const lamportDifference = MIN_LAMPORTS != null ?  MIN_LAMPORTS_AUTO_SIGNER - lamportBalance: 0

            if (needsUpdate) {
              tx.add(
                await playerToken.updateSessionAuthorityIxn(
                  sessionAuthorityKp.publicKey,
                  new Date(Date.now() + 86_400_000),
                  lamportDifference > 0 ? (LAMPORT_TOPUP_AUTO_SIGNER - lamportBalance) : 0
                )
              );

              needsWalletSigner = true
            }
          } else if (allowsAutoSigning == false) {
            const needsUpdate = playerToken?.sessionAuthority != null && (playerToken?.sessionAuthority?.toString() != walletPubkey.toString())

            if (needsUpdate) {
              tx.add(
                await playerToken.updateSessionAuthorityIxn(
                  walletPubkey,
                  new Date(),
                  0
                )
              );

              needsWalletSigner = true
            }
          }
        } else if (isDelegated == true) {
          // HANDLE CASE WHERE SESSION AUTH IS DIFFERENT ON ER
          if (sessionAuthorityKp != undefined) {
            const needsUpdate = playerToken?.sessionAuthority == null || sessionAuthorityKp.publicKey.toString() != playerToken.sessionAuthority.toString()

            if (needsUpdate) {
              tx.add(
                await playerToken.updateSessionAuthorityIxn(
                  sessionAuthorityKp.publicKey,
                  new Date(Date.now() + 86_400_000),
                  0
                )
              );

              needsWalletSigner = true
            }
          } else if (allowsAutoSigning == false) {
            const needsUpdate = playerToken?.sessionAuthority != null && (playerToken?.sessionAuthority?.toString() != walletPubkey.toString())

            if (needsUpdate) {
              tx.add(
                await playerToken.updateSessionAuthorityIxn(
                  walletPubkey,
                  new Date(),
                  0
                )
              );

              needsWalletSigner = true
            }
          }
        }

        // ACTUAL WALLET WILL NEED TO SIGN IF DEPOSIT/INIT REQUIRED
        const usingSessionKeypair =
          sessionAuthorityKp != null && needsWalletSigner == false;
        const ownerOrAuth = usingSessionKeypair
          ? sessionAuthorityKp.publicKey
          : walletPubkey;

        const identifier = gameInstance?.identifier;

        // INIT GAME AND PLACE BET
        const initAndBetIx = gameSpec.isSolo
          ? await gameSpec.soloBetIx(
            ownerOrAuth,
            playerToken,
            inputs,
            wager,
            clientSeed,
            identifier
          )
          : await gameSpec.multiBetIx(
            ownerOrAuth,
            playerToken,
            inputs,
            wager,
            clientSeed,
            identifier
          );

        tx.add(initAndBetIx);

        const isBaseTx = playerToken?.houseToken.isDelegated == false
        const clientToUse = isBaseTx ? client: erClient;
        
        const sig = await sendTransaction(
          tx.instructions, 
          clientToUse, 
          usingSessionKeypair ? sessionAuthorityKp.publicKey: walletPubkey, 
          isBaseTx,
          chain, 
          meta?.errorByCodeByProgram,
          undefined,
          usingSessionKeypair ? undefined: solanaRpc.signTransaction,
          usingSessionKeypair ? sessionAuthorityKp: undefined,
          undefined,
          undefined,
          onSuccessfulSendCallback
        )

        console.error(`GOT THE SIG ${sig}`, { cl: clientToUse })

        // IF CREATING THE PLAYER TOKEN, LOAD IT FOR CONTEXT
        if (creatingPlayerToken == true) {
          await clientToUse?.confirmTransaction(sig, "confirmed");

          await loadPlayerToken();
        }

        return sig;
      } catch (err) {
        // PUT IN FOR CRUDE TESTING OF ISSUES, TO BE REMOVED
        console.log({
          err
        })
        if (err == null || err?.message == "User rejected the request." || err?.name == "WalletSignTransactionError") {
          if (onUserRejected != null) {
            onUserRejected();
          }
          return;
        }

        console.warn("Error placing bet", err);
        if (onErrorCallback) {
          onErrorCallback(err);
        }
      }
    },
    [
      solanaRpc,
      walletPubkey,
      counter,
      gameSpec,
      client,
      erClient,
      signerKp,
      lamportBalance,
      meta,
      house,
      playerMeta,
      recentBlockhash,
      networkCounter,
      playerToken,
      loadPlayerToken,
      loadPlayerTokens,
      allowsAutoSigning,
      allowsAutoDeposit,
      chain
    ]
  );

  // VALIDATIONS
  const { selectedTokenMeta } = useContext(BalanceContext);
  const [validation, setValidation] = useState<IGameSpecValidation>();

  useEffect(() => {
    // ONLY VALIDATE IF GAME SPEC AND SELECTED TOKEN IN CONTEXT
    if (gameSpec == null || selectedTokenMeta == null) {
      return;
    }
  
    const gameSpecToken = gameSpec.supportedTokenByMint?.get(selectedTokenMeta.mint)
    const gameSpecTokenStatus = gameSpecToken?.status != null ?  toGameSpecTokenStatus(gameSpecToken.status): undefined;
    const gameSpecTokenIsActive = gameSpecTokenStatus != null && GAME_SPEC_TOKEN_STATUS_TAKING_BETS.includes(gameSpecTokenStatus)
    const needsUpdateBeforeValidate = gameSpecPubkeyString != gameSpec.publicKey.toString()

    // CHECK THE GAME SPEC TOKEN 

    setValidation({
      takingBets:
        gameSpec.status != null &&
        GAME_STATUS_TAKING_BETS.includes(gameSpec.status),
      tokenTakingBets: needsUpdateBeforeValidate || gameSpecTokenIsActive
    });
  }, [gameSpec, selectedTokenMeta, gameSpecPubkeyString]);

  const { gameValidation } = useContext(ErrorHandlingContext);
  useEffect(() => {
    if (gameSpec == null || validation == null) {
      return;
    }

    if (validation.takingBets == false) {
      gameValidation.addErrorMessage({
        type: ErrorType.GAME_NOT_ACTIVE,
        title: "Game not active",
        message: "The game is not currently taking bets.",
      });
    } else {
      gameValidation.removeErrorMessage(ErrorType.GAME_NOT_ACTIVE);
    }

    if (validation.tokenTakingBets == false) {
      gameValidation.addErrorMessage({
        type: ErrorType.GAME_TOKEN_NOT_ACTIVE,
        title: "Game token not active",
        message: "The game is not currently taking bets from this token.",
      });
    } else {
      gameValidation.removeErrorMessage(ErrorType.GAME_TOKEN_NOT_ACTIVE);
    }
  }, [gameSpec, validation]);

  const resultModalDefaultData = {
    title: "Payout",
    isModalVisible: false,
    multiplier: 0,
    payout: 0,
    hideTimeout: 3000,
    tokenIcon: "usdc",
    shouldHideBackground: false,
    className: "",
    onModalClose: () => { },
  };
  const [resultModalData, setResultModalData] = useState<ResultModalData>(
    resultModalDefaultData
  );
  const location = useLocation();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (resultModalData?.isModalVisible) {
      const id = Math.random() * 10;
      timeoutId = setTimeout(
        () => {
          setResultModalData((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));

          setTimeout(() => {
            resultModalData.onModalClose?.();
            setResultModalData(resultModalDefaultData);
          }, WIN_MODAL_ANIMATION_DURATION);
        },
        (resultModalData.hideTimeout || resultModalDefaultData.hideTimeout) -
        WIN_MODAL_ANIMATION_DURATION
      );
    }
    return () => {
      if (resultModalData?.isModalVisible && timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [resultModalData, setResultModalData]);

  useEffect(() => {
    setResultModalData(resultModalDefaultData);
  }, [location]);

  useEffect(() => {
    if (gameUptoDateWithChain == false && loadedChain == chain) {
      setGameUptoDateWithChain(true)
    } else if (gameUptoDateWithChain == true && loadedChain != chain) {
      setGameUptoDateWithChain(false)
    }
  }, [loadedChain, chain])

  return (
    <GameContext.Provider
      value={useMemo(
        () => ({
          gameSpec: gameSpec,
          gameInstance: gameInstance,
          updateGameInstance: updateGameInstance,
          loadGameInstance: loadGameInstance,
          gameSpecLoaded: gameSpecLoaded,
          gameConfig: gameConfig,
          initAndBet: initAndBet,
          setResultModalData: (newResultModalData: ResultModalData) => {
            setResultModalData((prevResultModalData) => ({
              ...prevResultModalData,
              ...newResultModalData,
            }));
          },
          resetResultModalData: () =>
            setResultModalData(resultModalDefaultData),
          resultModalData,
          gameInstancePubkey,
          setGameInstancePubKey,
          setGameInstance: setGameInstance,
          gameUptoDateWithChain: gameUptoDateWithChain
        }),
        [
          gameSpec,
          gameConfig,
          initAndBet,
          gameInstance,
          gameSpecLoaded,
          resultModalData,
          gameInstancePubkey,
          setGameInstancePubKey,
          gameUptoDateWithChain
        ]
      )}
    >
      {children}
    </GameContext.Provider>
  );
};