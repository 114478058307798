import axios from 'axios'
import { NetworkType, defaultNetwork } from '../chain/network'
import { ClaimableStatus, CollectableStatus } from '../../sdk/betStream'
import { CLAIM_EVENT_TYPE, ClaimType, IAuthWallet, IBetHistory, IBettingHistoryFilter, ICreateReferralSchemePayload, IGetDataPayload, IGetDataResp, IGetJackpotWinsFilter, IGetJackpotWinsResp, IGetNftTraitWinsFilter, IGetNftTraitWinsResp, IGetReferralMetricsPayload, IGetReferralSchemesPayload, IGetReferralSettlementsPayload, IGetStakingActivityFilter, IGetStakingActivityResp, IGetTraitWinsFilter, IGetTraitWinsResp, IJackpotWin, IJwtPayload, IJwtResponse, ILeaderboardFilters, INftTraitWin, IPlayerClaim, IPlayerClaimsFilter, IPlayerClaimsResp, IPlayerCollect, IPlayerCollectsFilter, IPlayerCollectsResp, IPlayerPointsFilters, IReferralMetric, IReferralScheme, IReferralSchemeConfig, IReferralSettlement, IReferralStat, IReferredPlayer, IRevenueAggregationFilters, ISetDataPayload, ISetDataResp, IStakingActivity, ITraitWin, ITransactionHistory, ITransactionHistoryFilter, IValidateReferralIdPayload, IValidateReferralIdResp } from './types'
import { ADMIN_PLAYER_BETS, ADMIN_REFERRAL_CONFIG, ADMIN_REFERRAL_METRICS, ADMIN_REFERRAL_SCHEMES, ADMIN_REFERRAL_SETTLEMENTS, ADMIN_REFERRAL_STATS, ADMIN_TRANSACTION_HISTORY, ADMIN_WALLETS, CREATE_REFERRAL_SCHEME, GET_AGGREGATED_REVENUE, GET_DATA_ENDPOINT, GET_JACKPOT_WINS_ENDPOINT, GET_JWT_ENDPOINT, GET_LEADERBOARD_PLAYERS_ENDPOINT, GET_NFT_TRAIT_WINS_ENDPOINT, GET_PLAYER_CLAIMS_ENDPOINT, GET_PLAYER_COLLECTS_ENDPOINT, GET_PLAYER_POINTS, GET_PYTH_TOKEN_PRICES, GET_REFERRAL_METRICS, GET_REFERRAL_SCHEMES, GET_REFERRAL_SETTLEMENTS, GET_REFERRAL_STATS, GET_REFERRED_PLAYERS, GET_STAKING_ACTIVITIES_ENDPOINT, GET_TRAIT_WINS_ENDPOINT, PLAYER_BET_HISTORY, PLAYER_TRANSACTION_HISTORY, SET_DATA_ENDPOINT, TIME_MAINNET_STAKING_BEGINS, VALIDATE_REFERRAL_ID } from './constants'
import { APP_NETWORK_TYPE } from '../../types/chain'

export const getJwt = async (payload: IJwtPayload): Promise<IJwtResponse> => {
    const response = await axios.post(GET_JWT_ENDPOINT, payload, {
        headers: {
            "Content-Type": "application/json"
        }
    })

    return response.data
}

export const getData = async (payload: IGetDataPayload): Promise<IGetDataResp> => {

    const response = await axios.post(GET_DATA_ENDPOINT, {
        wallet: payload.wallet
    }, {headers: {
        // Authorization: `Bearer ${payload.jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data.data
}

export const setData = async (payload: ISetDataPayload): Promise<ISetDataResp> => {
    const jwt = payload.jwt
    
    delete payload.jwt

    const response = await axios.post(SET_DATA_ENDPOINT, payload, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data
}

export const getStakingActivity = async (filters: IGetStakingActivityFilter): Promise<IGetStakingActivityResp[]> => {
    if (defaultNetwork == NetworkType.MAINNET && filters.timeFrom == null) {
        filters.timeFrom = TIME_MAINNET_STAKING_BEGINS
    }

    const response = await axios.post(GET_STAKING_ACTIVITIES_ENDPOINT, filters, {headers: {
        "Content-Type": "application/json"
    }})

    return response.data
}

export const getFormattedStakingActivities = async (filters: IGetStakingActivityFilter): Promise<IStakingActivity[]> => {
    const stakingActivities = await getStakingActivity(filters)

    return stakingActivities.map((record) => {
        return {
            activity: record.event_type,
            time: new Date(record.event_timestamp * 1000),
            nftMint: record.nftmint,
            owner: record.owner,
            signature: record.signature
        }
    })
}

export const getJackpotWins = async (filters: IGetJackpotWinsFilter): Promise<IGetJackpotWinsResp[]> => {

    if (defaultNetwork == NetworkType.MAINNET && filters.timeFrom == null) {
        filters.timeFrom = TIME_MAINNET_STAKING_BEGINS
    }

    const response = await axios.post(GET_JACKPOT_WINS_ENDPOINT, filters, {headers: {
        "Content-Type": "application/json"
    }})

    return response.data
}

export const getFormattedJackpotWins = async (filters: IGetJackpotWinsFilter, decimals: number = 6): Promise<IJackpotWin[]> => {
    const jackpotWins = await getJackpotWins(filters)

    const formatted = jackpotWins?.map((record) => {
        const data = record.data

        const combined = {
            ...record,
            ...data,
        }

        return {
            ...combined,
            time: new Date(combined.event_timestamp * 1000),
            amount: parseInt(combined.amount, 16)
        }
    })

    return formatted
}

export const getTraitWins = async (filters: IGetTraitWinsFilter): Promise<IGetTraitWinsResp[]> => {
    if (defaultNetwork == NetworkType.MAINNET && filters.timeFrom == null) {
        filters.timeFrom = TIME_MAINNET_STAKING_BEGINS
    }

    const response = await axios.post(GET_TRAIT_WINS_ENDPOINT, filters, {headers: {
        "Content-Type": "application/json"
    }})

    return response.data
}

export const getFormattedTraitWins = async (filters: IGetTraitWinsFilter, decimals: number = 6): Promise<ITraitWin[]> => {
    // GET TRAIT WINS FOR ALL ATTRIBUTE / TRAIT PAIRS

    const traitWins = await getTraitWins(filters)

    const formatted = traitWins.map((record) => {
        const data = record.data
        const combined = {
            ...record,
            ...data
        }

        return {
            ...combined,
            time: new Date(combined.event_timestamp * 1000),
            amountPerStaker: parseInt(combined.amountPerStaker, 16),
        }
    })

    return formatted
}

export const getNftTraitWins = async (filters: IGetNftTraitWinsFilter): Promise<IGetNftTraitWinsResp[]> => {
    if (defaultNetwork == NetworkType.MAINNET && filters.timeFrom == null) {
        filters.timeFrom = TIME_MAINNET_STAKING_BEGINS
    }
    
    const response = await axios.post(GET_NFT_TRAIT_WINS_ENDPOINT, filters, {headers: {
        "Content-Type": "application/json"
    }})

    return response.data
}

export const getFormattedNftTraitWins = async (filters: IGetNftTraitWinsFilter): Promise<INftTraitWin[]> => {
    // GET TRAIT WINS FOR ALL ATTRIBUTE / TRAIT PAIRS
    const nftTraitWins = await getNftTraitWins(filters)

    const formatted = nftTraitWins?.map((record) => {
        const traitSelectedData = record.trait_selected_data
        const individualTraitUpdateData = record.individual_trait_update_data

        const combined = {
            ...traitSelectedData,
            ...individualTraitUpdateData,
            ...record
        }

        return {
            ...combined,
            time: new Date(combined.timestamp * 1000),
            amountPerStaker: parseInt(combined.amountPerStaker, 16),
            amount: parseInt(combined.amount, 16)
        }
    })

    return formatted
}

export const getPlayerClaims = async (filters: IPlayerClaimsFilter): Promise<IPlayerClaimsResp[]> => {
    const response = await axios.post(GET_PLAYER_CLAIMS_ENDPOINT, filters, {headers: {
        "Content-Type": "application/json"
    }})

    return response.data
}

export const daysInPeriod = (rewardType: string) => {
    switch(rewardType) {
        case ClaimType.DAILY:
            return 1
        case ClaimType.WEEKLY:
            return 7
        case ClaimType.MONTHLY:
            return 28
        case ClaimType.RAKEBACK:
            return 1
        case ClaimType.LEVEL_UP:
            return 0 // NOT ACTUALLY 1 Day....
        case ClaimType.REFERRAL:
            return 1
        default:
            console.error(`Unknown bonus type`, { rewardType })
            return 1
    }
}

export const getFormattedPlayerClaims = async (filters: IPlayerClaimsFilter): Promise<IPlayerClaim[]> => {
    const playerClaims = await getPlayerClaims(filters)

    const formatted = playerClaims.map((record, index) => {

        const data = record.data
        const combined = {
            ...data,
            ...record
        }

        combined.timestamp = parseInt(combined.timestamp, 16)
        combined.relatesTo = parseInt(combined.relatesTo, 16)
        combined.tokenAmountSpread = parseInt(combined.tokenAmountSpread, 16)
        combined.tokenAmountUpFront = parseInt(combined.tokenAmountUpFront, 16)
        combined.valueBase = parseInt(combined.valueBase, 16)

        const rewardTypeKey = Object.keys(combined.rewardType)[0]
        const days = daysInPeriod(rewardTypeKey)
        
        let dateFrom: Date | undefined
        let dateTo: Date | undefined

        // FOR LEVEL UP WE USE TIMESTAMP
        if (rewardTypeKey == ClaimType.LEVEL_UP) {
            dateFrom = new Date(combined.timestamp * 1000)
            dateTo = new Date(combined.timestamp * 1000)
        } else {
            dateFrom = new Date(combined.relatesTo * 1000)
            dateFrom.setDate(dateFrom.getDate() + days)
    
            dateTo = new Date(combined.relatesTo * 1000)
            dateTo.setDate(dateTo.getDate() + (days * 2))
            dateTo.setSeconds(dateTo.getSeconds() - 1)
        }

        return {
            ...combined,
            type: rewardTypeKey,
            startDate: dateFrom,
            endDate: dateTo,
            relatesTo: new Date(combined.relatesTo * 1000),
            eventType: combined.event_type,
            status: record.event_type == CLAIM_EVENT_TYPE.REWARD_CLAIMED ? ClaimableStatus.CLAIMED: ClaimableStatus.FOREFIT
        }
    })

    return formatted
}

export const getPlayerCollects = async (filters: IPlayerCollectsFilter): Promise<IPlayerCollectsResp[]> => {
    const response = await axios.post(GET_PLAYER_COLLECTS_ENDPOINT, filters, {headers: {
        "Content-Type": "application/json"
    }})

    return response.data
}

export const getFormattedPlayerCollects = async (filters: IPlayerCollectsFilter): Promise<IPlayerCollect[]> => {
    const playerCollects = await getPlayerCollects(filters)

    const formatted = playerCollects.map((record) => {
        const data = record.data
        const combined = {
            ...data,
            ...record
        }

        combined.amount = parseInt(combined.amount, 16)
        combined.rewardDate = parseInt(combined.rewardDate, 16)
        combined.timestamp = parseInt(combined.timestamp, 16)

        return {
            ...combined,
            tokenIcon: '',
            eventType: combined.event_type,
            status: combined.event_type == "RewardCalendarCollection" ? CollectableStatus.COLLECTED: CollectableStatus.FOREFIT,
            amount: combined.amount,
            rewardDate: new Date(combined.rewardDate * 1000),
            eventTime: new Date(combined.timestamp * 1000),
            amountUi: 0,
            amountUsdUi: 0,
            amountUsd: 0
        }
    })

    return formatted
}

export interface ITokenPriceMeta {
    slug: string
    name: string
    symbol: string
    decimals: number
    feed_id: string
    publish_time: number
    price_basis: number
    price: number
}

export const getPriceData = async (filters: {
    symbol: string
}): Promise<ITokenPriceMeta[]> => {
    const response = await axios.post(GET_PYTH_TOKEN_PRICES, filters, {headers: {
        "Content-Type": "application/json"
    }})

    return response.data
}

export const loadLeaderboardData = async (filters: ILeaderboardFilters) => {

    return await axios.post(GET_LEADERBOARD_PLAYERS_ENDPOINT, filters, {headers: {
        "Content-Type": "application/json"
    }})
  };

  export const loadMergedLeaderboardData = async (filters: ILeaderboardFilters) => {
    const leaderboard = await loadLeaderboardData(filters);

    // COMBINE THE 2 HERE

    return {
        leaderboard: leaderboard
    }
  }


  export const loadPlayerPoints = async (filters: IPlayerPointsFilters) => {

    return await axios.post(GET_PLAYER_POINTS, filters, {headers: {
        "Content-Type": "application/json"
    }})
  };

  export const loadRevenueAggregation = async (filters: IRevenueAggregationFilters) => {

    return await axios.post(GET_AGGREGATED_REVENUE, filters, {headers: {
        "Content-Type": "application/json"
    }})
  };


  export const validateReferralIdentifier = async (payload: IValidateReferralIdPayload): Promise<IValidateReferralIdResp> => {
    const jwt = payload.jwt
    
    delete payload.jwt

    const response = await axios.post(VALIDATE_REFERRAL_ID, payload, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data
}

export const createReferralScheme = async (payload: ICreateReferralSchemePayload): Promise<IReferralScheme> => {
    const jwt = payload.jwt
    
    delete payload.jwt

    const response = await axios.post(CREATE_REFERRAL_SCHEME, payload, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data
}

export const fetchReferralSchemes = async (payload: IGetReferralSchemesPayload): Promise<IReferralScheme[]> => {
    const jwt = payload.jwt
    
    delete payload.jwt

    const response = await axios.post(GET_REFERRAL_SCHEMES, payload, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data
}

export const fetchReferralStats = async (payload: IGetReferralSchemesPayload): Promise<IReferralStat[]> => {
    const jwt = payload.jwt
    
    delete payload.jwt

    const response = await axios.post(GET_REFERRAL_STATS, payload, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data
}

export const fetchReferredPlayers = async (payload: IGetReferralSchemesPayload): Promise<IReferredPlayer[]> => {
    const jwt = payload.jwt
    
    delete payload.jwt

    const response = await axios.post(GET_REFERRED_PLAYERS, payload, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data
}

export const fetchReferralMetrics = async (payload: IGetReferralMetricsPayload): Promise<IReferralMetric[]> => {
    const jwt = payload.jwt
    
    delete payload.jwt


    const response = await axios.post(GET_REFERRAL_METRICS, payload, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data
}

export const fetchReferralSettlements = async (payload: IGetReferralSettlementsPayload): Promise<IReferralSettlement[]> => {
    const jwt = payload.jwt
    
    delete payload.jwt


    const response = await axios.post(GET_REFERRAL_SETTLEMENTS, payload, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data
}

export const fetchAdminReferralSettlements = async (payload: IGetReferralSettlementsPayload): Promise<IReferralSettlement[]> => {
    const jwt = payload.jwt
    
    delete payload.jwt


    const response = await axios.post(ADMIN_REFERRAL_SETTLEMENTS, payload, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data
}

export const getAdminWallets = async (): Promise<IAuthWallet[]> => {
    const response = await axios.get(ADMIN_WALLETS)


    return response.data
}

export const getAdminReferralConfig = async (payload: {
    jwt: string
}): Promise<IReferralSchemeConfig[]> => {
    const jwt = payload.jwt

    const response = await axios.get(ADMIN_REFERRAL_CONFIG, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data
}

export const updateAdminReferralConfig = async (payload: IReferralSchemeConfig): Promise<IReferralSchemeConfig[]> => {

    const jwt = payload.jwt
    
    delete payload.jwt

    const response = await axios.put(ADMIN_REFERRAL_CONFIG, payload, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data
}

export const getAdminReferralSchemes = async (payload: {
    jwt: string
    referralId?: string,
    wallet?: string
}): Promise<IReferralScheme[]> => {
    const jwt = payload.jwt
    const hasReferralId = payload.referralId != null && payload.referralId.length > 0
    const hasWallet = payload.wallet != null && payload.wallet.length > 0

    const extension = (hasReferralId || hasWallet) ? `${hasReferralId ? `?referralId=${payload.referralId}`: ''}${hasWallet ? `${hasReferralId ? '&': '?'}wallet=${payload.wallet}`: ''}`: ''
    const url = `${ADMIN_REFERRAL_SCHEMES}${extension}`

    const response = await axios.get(url, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data
}

export const updateAdminReferralScheme = async (payload: IReferralScheme): Promise<IReferralScheme[]> => {
    const jwt = payload.jwt

    delete payload.jwt

    const response = await axios.put(ADMIN_REFERRAL_SCHEMES, payload, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data
}

export const getAdminReferralStats = async (payload: {
    jwt: string
    referralId?: string,
    wallet?: string
}): Promise<IReferralStat[]> => {
    const jwt = payload.jwt
    const hasReferralId = payload.referralId != null && payload.referralId.length > 0
    const hasWallet = payload.wallet != null && payload.wallet.length > 0

    const extension = (hasReferralId || hasWallet) ? `${hasReferralId ? `?referralId=${payload.referralId}`: ''}${hasWallet ? `${hasReferralId ? '&': '?'}wallet=${payload.wallet}`: ''}`: ''
    const url = `${ADMIN_REFERRAL_STATS}${extension}`

    const response = await axios.get(url, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data
}

export const getAdminReferralMetrics = async (payload: {
    jwt?: string
    referralId?: string,
    wallet?: string
}): Promise<IReferralMetric[]> => {
    const jwt = payload.jwt

    delete payload.jwt

    const response = await axios.post(ADMIN_REFERRAL_METRICS, payload, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data
}

// LOAD BETS
export const loadBettingHistory = async (payload: IBettingHistoryFilter): Promise<IBetHistory[]> => {
    const jwt = payload.jwt
    
    delete payload.jwt

    const response = await axios.post(PLAYER_BET_HISTORY, payload, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data.map((record: any) => {
        return {
            ...record,
            multiplier: record.payout / record.wager
        }
    })
}

// LOAD TX HISTORY
export const loadTxnHistory = async (payload: ITransactionHistoryFilter): Promise<ITransactionHistory[]> => {
    const jwt = payload.jwt
    
    delete payload.jwt

    const response = await axios.post(PLAYER_TRANSACTION_HISTORY, payload, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data.map((history: any) => {
        const data = history.data
        const combined = {
            ...data,
            ...history
        }

        delete combined.data

        const tokenAmount = parseInt(combined.tokens, 16)

        combined.tokens = tokenAmount
        combined.timestamp = parseInt(combined.timestamp, 16)
        combined.isDeposit = tokenAmount > 0
        
        return combined
    })
}

// ADMIN BETS + TXN HISTORY
export const loadAdminPlayerBets = async (payload: IBettingHistoryFilter): Promise<IBetHistory[]> => {
    const jwt = payload.jwt
    
    delete payload.jwt

    const response = await axios.post(ADMIN_PLAYER_BETS, payload, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data.map((record: any) => {
        return {
            ...record,
            multiplier: record.payout / record.wager
        }
    })
}

// LOAD TX HISTORY
export const loadAdminTxnHistory = async (payload: ITransactionHistoryFilter): Promise<ITransactionHistory[]> => {
    const jwt = payload.jwt
    
    delete payload.jwt

    const response = await axios.post(ADMIN_TRANSACTION_HISTORY, payload, {headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json"
    }})

    return response.data.map((history: any) => {
        const data = history.data
        const combined = {
            ...data,
            ...history
        }

        delete combined.data

        const tokenAmount = parseInt(combined.tokens, 16)

        combined.tokens = tokenAmount
        combined.timestamp = parseInt(combined.timestamp, 16)
        combined.isDeposit = tokenAmount > 0
        
        return combined
    })
}

export const toChainFilter = (chain: APP_NETWORK_TYPE): string => {
    if (chain == APP_NETWORK_TYPE.MB_AND_SOLANA) {
        return "MAGIC_BLOCK"
    } else if (chain == APP_NETWORK_TYPE.SONIC) {
        return "SONIC_V1"
    } else {
        return chain
    }
}