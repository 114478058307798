import { APP_NETWORK_TYPE } from "../types/chain";
import { defaultNetwork, NetworkType } from "../utils/chain/network";
import { IS_MAINNET } from "../utils/solana/rpc";

export const networksData: {
  [key in APP_NETWORK_TYPE]: {
    name: string,
    icon: string,
    hoverIcon?: string
  }
} = {
  [APP_NETWORK_TYPE.SOLANA]: {
    name: "Solana",
    icon: "/static/networks-icons/sol-white.png",
    hoverIcon: "/static/networks-icons/sol-black.png"
  },
  [APP_NETWORK_TYPE.SONIC]: {
    name: "Sonic",
    icon: "/static/networks-icons/sonic.png"

  },
  [APP_NETWORK_TYPE.MB_AND_SOLANA]: {
    name: "Solana",
    icon: "/static/networks-icons/sol-white.png",
    hoverIcon: "/static/networks-icons/sol-black.png"
  },
};

// ONLY ON MB FOR NOW - SONIC TO FOLLOW
export const ENABLED_NETWORKS = IS_MAINNET ? [APP_NETWORK_TYPE.MB_AND_SOLANA, APP_NETWORK_TYPE.SONIC] : [
  APP_NETWORK_TYPE.SONIC,
  APP_NETWORK_TYPE.MB_AND_SOLANA
];

export const SONIC_BRIDGE_URL = defaultNetwork == NetworkType.MAINNET
  ? "https://bridge-api-staging.sonic.game"
  : "https://bridge-api-staging.sonic.game";